.gm-fullscreen-control{
    height: 20px !important;
    width: 20px !important;
}
.gm-control-active{
    width:26px !important;
    height:25px !important;
};
.gmnoprint{
    div[title="Show street map with terrain"]{
        width:fit-content !important;
    }
    div[draggable=false ] {
        width: 26px !important;
    // height: 53px !important;
    height:fit-content !important;
    }
   
    img{
        max-width: 15px !important;
    }
}
.gm-style-mtc {
    div[role='button'] {
        height: 25px !important;
        font-size: 15px !important;
    }
   
}

.gmnoprint{
        div[title="Show imagery with street names"]{
           display:block;
           width:80px!important;
           height:30px!important;
           font-size:15px !important;
        }
        div[title="Show street map with terrain"]{
            display: none;
        }
        button[title="Rotate map 90 degrees"]{
          display:"none"
        }
       
    }
    button[title="Tilt map"]{
      display: none;
    }
.gm-style .controls {
    font-size: 28px;  /* this adjusts the size of all the controls */

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 300;
    height: 1em;
    margin: 6px;
    text-align: center;
    user-select: none;
    padding: 2px;
    width: 1em;
  }
  .gm-style .controls button {
    border: 0;
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
  }
  .gm-style .controls button:hover {
    color: rgba(0, 0, 0, 0.9);
  }

  .gm-style .controls.zoom-control {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .gm-style .controls.zoom-control button {
    font: 0.85em Arial;
    margin: 1px;
    padding: 0;
  }

  .gm-style .controls.maptype-control {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  .gm-style .controls.maptype-control button {
    display: inline-block;
    font-size: 0.5em;
    margin: 0 1px;
    padding: 0 6px;
  }
  .gm-style .controls.maptype-control.maptype-control-is-map .maptype-control-map {
    font-weight: 700;
  }
  .gm-style .controls.maptype-control.maptype-control-is-satellite .maptype-control-satellite {
    font-weight: 700;
  }

  .gm-style .controls.fullscreen-control button {
    display: block;
    font-size: 1em;
    height: 100%;
    width: 100%
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon {
    border-style: solid;
    height: 0.25em;
    position:absolute;
    width: 0.25em;
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-top-left {
    border-width: 2px 0 0 2px;
    left: 0.1em;
    top: 0.1em;
  }
  .gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-top-left {
    border-width: 0 2px 2px 0;
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-top-right {
    border-width: 2px 2px 0 0;
    right: 0.1em;
    top: 0.1em;
  }
  .gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-top-right {
    border-width: 0 0 2px 2px;
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-bottom-left {
    border-width: 0 0 2px 2px;
    left: 0.1em;
    bottom: 0.1em;
  }
  .gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-bottom-left {
    border-width: 2px 2px 0 0;
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon.fullscreen-control-bottom-right {
    border-width: 0 2px 2px 0;
    right: 0.1em;
    bottom: 0.1em;
  }
  .gm-style .controls.fullscreen-control.is-fullscreen .fullscreen-control-icon.fullscreen-control-bottom-right {
    border-width: 2px 0 0 2px;
  }

  .labels-markers{
    // color: #fff;
    font-weight: bold;
    font-size: 16px;
    opacity: 1;
    pointer-events: none;
    text-align: center;
    // width: 60px;
    white-space: nowrap;
    background-color: none;
    color: yellow;
    z-index:999999999 !important;
}

.loader {
  
  border-radius: 50%;
  border-top: -22px solid blue;
  border-bottom: 4px solid #F9A604;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: 45%;
  left: 45%;
  position: absolute;
}

@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


.loaderWrapperStyle {
  position: absolute;
  background: #fafbfc4f;
  display: flex;
align-items: center;
justify-content: center;
overflow: auto;
width: 100%;
height: 100%;
/* background-color:#f5f3f3; */
flex-direction:row-reverse;
z-index:999999999;
text-align:center;
/* position: fixed; */
top: 0;
left: 0;
}

.outerCustomControl{
  background-color: white;
  border: 2px solid white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  cursor: pointer;
  margin-right: 22px;
  text-align: center;
  width: 30px;
}
.innerCustomControl{
  color: #191919;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  line-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
}
.outerDiv{
  max-width: 10%;
    position: absolute;
}
.icon-Reset{
  font-weight: bold;
    color: #757373
}

.containerDiv{
  background-color: white;
  border: 2px solid white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  cursor: pointer;
  margin-right: 22px;
  text-align: center;
}
.divWrapper{
  max-width: 10% !important;
    position: absolute;
    top: 51%;
    margin-left: 12px;
}
.fontGmap{
  font-size: 15px;
  padding-right: 12px;
}
.ImgGmap{
  // padding-left: 12px;
  padding-right: 5px;
}
.flexGmap{
  // display: flex;
  // flex-flow: row wrap;
  // position: absolute;
  // // width: 14%;
  // background-color: white;
  // background-color: white;
  // border: 2px solid white;
  // border-radius: 3px;
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  // cursor: pointer;
  // margin-right: 22px;
  // text-align: center;
  top: 40px;
    display: flex;
    flex-flow: row wrap;
    // position: relative;
    background-color: white;
    background-color: white;
    border: 2px solid white;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
    cursor: pointer;
    margin-right: 22px;
    /* text-align: center; */
    float: right;
    text-align: center;
}
.custom{
  color: #191919;
    font-family: Roboto, Arial, sans-serif;
    font-size: 16px;
    padding-left: 5px;
    padding-right: -7px;
    /* padding-top: 4px; */
    position: absolute;
    right: 0;
    top: 10px;
}